import axios from 'axios';

// Environment variable for backend API URL
const API_URL = process.env.REACT_APP_API_URL;

// Upload file
export async function uploadFile(dir, file) {
    const formData = new FormData();
    formData.append('dir', dir);
    formData.append('file', file); // File object from input[type="file"]

    try {
        const response = await axios.post(`${API_URL}/admins/s3/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data; // Return upload result
    } catch (err) {
        alert(`Error uploading file: ${err.response?.data || err.message}`);
        return false;
    }
}

// List files
export async function listFiles(dir) {
    try {
        const response = await axios.post(`${API_URL}/admins/s3/list`, { dir });
        return response.data;
    } catch (err) {
        alert(`There was an error listing your files: ${err.response?.data || err.message}`);
        return false;
    }
}

// Delete file
export async function deleteFile(dir) {
    try {
        const response = await axios.post(`${API_URL}/admins/s3/delete`, { dir });
        return response.data === "File deleted successfully.";
    } catch (err) {
        alert(`There was an error deleting your photo: ${err.response?.data || err.message}`);
        return false;
    }
}
